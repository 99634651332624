<template>
  <div class="all-return">
    <WsMain>
      <WsText
        size="20"
        class="mt-6"
      >
        申請全部退訂
      </WsText>
      <WsCard class="mt-20">
        <WsText
          size="20"
          class="mt-6"
        >
          退訂原因
        </WsText>
        <ValidationObserver ref="formObserver">
          <WsStateForm
            :fields="fields"
            v-model="modelData"
          ></WsStateForm>
        </ValidationObserver>
      </WsCard>
      <WsCard class="mt-20">
        <WsCrud
          label="訂單資訊"
          :fields="table.fields"
          :modelName="table.modelName"
          :showFields="table.showFields"
          :hasFilter="false"
          inRowBtnRead
          :inRowBtnUpdate="false"
          :inRowBtnDelete="false"
          :creatable="false"
          :params="_params"
        >
        </WsCrud>
      </WsCard>
      <WsBtn @click="$_goBack()">取消</WsBtn>
      <WsBtn @click="$_submit()">送出</WsBtn>
    </WsMain>
  </div>
</template>
<script>
import ShopOrder from "@/__vue2stone_cms/models/shop_order";
import { ValidationObserver } from "vee-validate";
export default {
  name: "AllReturn",
  components: {
    ValidationObserver,
  },
  data: () => ({
    modelData: null,
    table: {
      modelName: "shop_order",
      showFields: ["no", "type", "created_at", "order_price"],
      fields: ShopOrder.fields,
    },
    loading: {
      submit: false,
    },
    fields: {
      return_reason: {
        type: "select",
        label: "退貨原因",
        placeholder: "選擇退貨原因",
        items: [
          {
            text: "貨物狀況不佳",
            value: 0,
          },
        ],
        rules: "required",
        col: 4,
      },
      remark: {
        type: "textarea",
        label: "退訂備註",
        placeholder: "輸入...",
        rules: "required",
        col: 12,
      },
    },
  }),
  computed: {
    _id() {
      return this.$route.query.id;
    },
    _params() {
      return {
        id: this._id,
      };
    },
  },
  methods: {
    async $_submit() {
      const isValidate = await this.$refs.formObserver.validate();
      if (!isValidate) {
        return;
      }
      try {
        this.loading.submit = true;
        const _id = this._id.split(",");
        const data = {
          shop_orders: _id,
          remark: this.modelData.remark,
          return_reason: this.modelData.return_reason,
        };
        await this.$axios.post(`/shop_return_record/return/all`, data);
      } catch (err) {
        alert(err);
      } finally {
        this.loading.submit = false;
        this.$_goBack();
      }
    },
    $_goBack() {
      if (this._id.split(",").length > 1) {
        this.$router.push(`/shop_order`);
      } else {
        this.$router.push(`/shop_order/${this._id}`);
      }
    },
  },
};
</script>
<style lang="scss">
</style>